import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import { ref, defineComponent, onMounted, getCurrentInstance, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import useQuasar from 'quasar/src/composables/use-quasar.js';
import debounce from 'quasar/src/utils/debounce.js';
;
import ClickOutside from "vue-click-outside";
import ResultRightSide from "../components/ResultRightSide.vue";
import NoResult from "../components/NoResult.vue";
import EmptyKeywords from "../components/EmptyKeywords.vue";
import Results from "../components/Results.vue";
import TiDBAI from "../components/TiDBAI.vue";
import { searchKeyword, getKeywordSuggestions as getKeywordSuggestionsFromServer } from "../api/search";
export default defineComponent({
  components: {
    ResultRightSide: ResultRightSide,
    NoResult: NoResult,
    EmptyKeywords: EmptyKeywords,
    Results: Results,
    TiDBAI: TiDBAI
  },
  directives: {
    ClickOutside: ClickOutside
  },
  setup: function setup() {
    var route = useRoute();
    var user = ref(null);
    var keyword = ref(route.query.q || "");
    var hasResults = ref(false);
    var resultsMeta = ref({});
    var hits = ref([]);
    var page = ref(1);
    var perPage = ref(10);
    var maxPage = ref(0);
    var searchTrackId = ref("");
    var searchSource = ref("");
    var router = useRouter();
    var $q = useQuasar();
    var foucsInputFlag = ref(false);
    var keywordSuggestions = ref([]);
    var canUseTiDBAI = ref(false);
    onBeforeMount(function () {
      var _window$siteConfig$us;

      keyword.value = route.query.q || "";
      user.value = window.siteConfig.user;
      canUseTiDBAI.value = Boolean((_window$siteConfig$us = window.siteConfig.user) === null || _window$siteConfig$us === void 0 ? void 0 : _window$siteConfig$us.canUseTiDBAI);

      if (route.name !== "index") {
        searchSource.value = route.name;
      }

      fetchResults();
    });
    onMounted(function () {
      getCurrentInstance().refs.search.focus();
    });

    function fetchResults() {
      if (!keyword.value) {
        return;
      }

      hasResults.value = true;
      $q.loading.show();
      searchKeyword(keyword.value, page.value, perPage.value, searchSource.value, searchTrackId.value).then(function (resp) {
        var respData = resp.data.data;
        resultsMeta.value = respData;
        page.value = respData.page_num;
        perPage.value = respData.per_page;
        searchTrackId.value = respData.track;
        hits.value = respData.hits;
        maxPage.value = Math.ceil(respData.total / respData.per_page);

        if (respData.total === 0) {
          hasResults.value = false;
        }
      }).finally(function () {
        $q.loading.hide();
      });
    }

    function refreshAndSearch() {
      router.push({
        query: {
          q: keyword.value
        }
      });
    }

    var getKeywordSuggestions = debounce(function (keyword) {
      keywordSuggestions.value = [];

      if (foucsInputFlag.value && keyword) {
        getKeywordSuggestionsFromServer(keyword).then(function (resp) {
          keywordSuggestions.value = resp.data.data || [];
        });
      }
    }, 1000);

    function searchLostFoucs() {
      setTimeout(function () {
        foucsInputFlag.value = false;
      }, 250);
    }

    return {
      user: user,
      keyword: keyword,
      hasResults: hasResults,
      resultsMeta: resultsMeta,
      hits: hits,
      page: page,
      perPage: perPage,
      maxPage: maxPage,
      searchTrackId: searchTrackId,
      searchSource: searchSource,
      foucsInputFlag: foucsInputFlag,
      keywordSuggestions: keywordSuggestions,
      canUseTiDBAI: canUseTiDBAI,
      refreshAndSearch: refreshAndSearch,
      fetchResults: fetchResults,
      getKeywordSuggestions: getKeywordSuggestions,
      searchLostFoucs: searchLostFoucs
    };
  },
  watch: {
    page: function page() {
      window.scroll(0, 0);
      this.hits = [];
      this.fetchResults();
    },
    keyword: function keyword(val) {
      this.getKeywordSuggestions(val || "");
    }
  }
});