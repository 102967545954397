import AskTUGItem from "./resultItem/asktug.vue";
import BlogItem from "./resultItem/blog.vue";
import DocItem from "./resultItem/doc.vue";
import CommunityBlogItem from "./resultItem/communityBlog.vue";
import PEItem from "./resultItem/pe.vue";
export default {
  props: ["resultsMeta", "hits", "searchTrackId", "searchSource", "user"],
  components: {
    AskTUGItem: AskTUGItem,
    BlogItem: BlogItem,
    DocItem: DocItem,
    CommunityBlogItem: CommunityBlogItem,
    PEItem: PEItem
  },
  setup: function setup() {
    return {};
  }
};