import { reportClick } from "../../api/search";
export default {
  props: ["data", "user", "searchTrackId"],
  setup: function setup(props) {
    function reportClickResult() {
      reportClick(props.data.url, props.searchTrackId);
    }

    return {
      reportClickResult: reportClickResult
    };
  }
};