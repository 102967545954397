import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = ["href"];
var _hoisted_2 = {
  class: "item-url"
};
var _hoisted_3 = {
  class: "item-bottom"
};
var _hoisted_4 = {
  class: "flex no-wrap"
};
var _hoisted_5 = ["href"];
var _hoisted_6 = ["src", "alt"];
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  class: "badges-row-container"
};
var _hoisted_9 = {
  key: 0,
  class: "score"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_q_badge = _resolveComponent("q-badge");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("a", {
    href: $props.data.url,
    class: "item-top",
    target: "_blank",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    }),
    onContextmenu: _cache[1] || (_cache[1] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    })
  }, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.data.url), 1), _createElementVNode("h3", null, _toDisplayString($props.data.data.title), 1)], 40, _hoisted_1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
    href: $props.data.url,
    target: "_blank",
    onClick: _cache[2] || (_cache[2] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    }),
    onContextmenu: _cache[3] || (_cache[3] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    })
  }, [_createElementVNode("img", {
    src: $props.data.data.img_url,
    alt: "".concat($props.data.data.title, " \u7684\u5C01\u9762\u56FE"),
    width: "180",
    class: "q-mr-sm"
  }, null, 8, _hoisted_6)], 40, _hoisted_5), _createElementVNode("div", null, [_createElementVNode("p", {
    innerHTML: $props.data.data.content,
    style: {
      "height": "80%"
    }
  }, null, 8, _hoisted_7), _createElementVNode("div", _hoisted_8, [_cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "badge-category-bg",
    style: {
      "background-color": "#3d3fea"
    }
  }, null, -1)), _cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "from"
  }, "PingCAP Education", -1)), $props.user && $props.user.isStaff ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_q_badge, {
    color: "blue"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode("Score: " + _toDisplayString($props.data.score), 1)];
    }),
    _: 1
  })])) : _createCommentVNode("", true)])])])])], 64);
}