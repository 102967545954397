import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_AskTUGItem = _resolveComponent("AskTUGItem");

  var _component_BlogItem = _resolveComponent("BlogItem");

  var _component_DocItem = _resolveComponent("DocItem");

  var _component_CommunityBlogItem = _resolveComponent("CommunityBlogItem");

  var _component_PEItem = _resolveComponent("PEItem");

  return _openBlock(), _createElementBlock("div", null, [$props.searchSource !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_1, "找到 " + _toDisplayString($props.resultsMeta.total) + " 条结果", 1)) : (_openBlock(), _createElementBlock("div", _hoisted_2, "已筛选出 " + _toDisplayString($props.resultsMeta.total) + " 条结果", 1)), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.hits, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.url
    }, [item.source === 'asktug' ? (_openBlock(), _createBlock(_component_AskTUGItem, {
      key: 0,
      data: item,
      user: $props.user,
      searchTrackId: $props.searchTrackId
    }, null, 8, ["data", "user", "searchTrackId"])) : _createCommentVNode("", true), item.source === 'blog' ? (_openBlock(), _createBlock(_component_BlogItem, {
      key: 1,
      data: item,
      user: $props.user,
      searchTrackId: $props.searchTrackId
    }, null, 8, ["data", "user", "searchTrackId"])) : _createCommentVNode("", true), item.source === 'docs-cn' ? (_openBlock(), _createBlock(_component_DocItem, {
      key: 2,
      data: item,
      user: $props.user,
      searchTrackId: $props.searchTrackId
    }, null, 8, ["data", "user", "searchTrackId"])) : _createCommentVNode("", true), item.source === 'community-blog' ? (_openBlock(), _createBlock(_component_CommunityBlogItem, {
      key: 3,
      data: item,
      user: $props.user,
      searchTrackId: $props.searchTrackId
    }, null, 8, ["data", "user", "searchTrackId"])) : _createCommentVNode("", true), item.source === 'pe' ? (_openBlock(), _createBlock(_component_PEItem, {
      key: 4,
      data: item,
      user: $props.user,
      searchTrackId: $props.searchTrackId
    }, null, 8, ["data", "user", "searchTrackId"])) : _createCommentVNode("", true)]);
  }), 128))])]);
}