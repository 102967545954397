import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { ref, onMounted } from "vue";
import { VMarkdownView } from "vue3-markdown";
import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";
import LoadingText from "./LoadingText.vue";
import { submitFeedback } from "@/api/ai";
export default {
  name: "AiAnswerCard",
  components: {
    VMarkdownView: VMarkdownView,
    LoadingText: LoadingText
  },
  props: ["keyword"],
  setup: function setup(props) {
    var localKeyword = ref(props.keyword);
    var displayedAnswer = ref("");
    var assistantMessageId = ref(null);
    var feedbackDialogVisible = ref(false);
    var loading = ref(true);
    var loadingText = ref("正在唤醒机器人");
    var translateWords = {
      "Identifying Your Question's Core Intents": "识别您问题的核心意图",
      "Searching the Knowledge Graph for Relevant Context": "在知识图谱中搜索相关上下文",
      "Query Rewriting for Enhanced Information Retrieval": "重写查询以增强信息检索",
      "Retrieving and Reranking the Best-Matching Data": "检索并重新排序最佳匹配数据",
      "Generating a Precise Answer with AI": "开始生成答案",
      "retrieve_knowledge_graph": "在知识图谱中搜索相关上下文",
      "vector_search": "在向量表中搜索相关上下文",
      "llm_generate": "开始生成答案"
    };
    var feedbackForm = ref({
      type: null,
      comment: ""
    });
    var feedbackLoading = ref(false);

    function askTiDBAI() {
      fetch("/tidb-ai/answer?q=".concat(encodeURIComponent(localKeyword.value))).then(function (resp) {
        var reader = resp.body.getReader();
        var stream = new ReadableStream({
          start: function start(controller) {
            function push() {
              reader.read().then(function (_ref) {
                var done = _ref.done,
                    value = _ref.value;

                if (done) {
                  controller.close();
                  self.loading = false;
                  return;
                }

                var chunkValue = new TextDecoder("utf-8").decode(value);
                chunkValue.split("\n\n").forEach(function (chunk) {
                  if (chunk.startsWith("0:")) {
                    var data = JSON.parse(chunk.replace("0:", ""));

                    if (data) {
                      loading.value = false;
                      displayedAnswer.value += data;
                    }
                  } else if (chunk.startsWith("2:") && chunk.includes("assistant_message")) {
                    var _data = JSON.parse(chunk.replace("2:", ""));

                    if (_data) {
                      assistantMessageId.value = _data[0].assistant_message.id;
                      console.log(assistantMessageId.value);
                    }
                  } else if (chunk.startsWith("8:")) {
                    var _data2 = JSON.parse(chunk.replace("8:", ""));

                    var display = _data2[0].display;
                    var state = _data2[0].state;

                    if (state && display) {
                      loadingText.value = translateWords[display] || display;
                    } else if (state == "REFINE_QUESTION") {
                      loadingText.value = "思考中";
                    }
                  } else if (chunk.startsWith("9:")) {
                    var _data3 = JSON.parse(chunk.replace("9:", ""));

                    var toolName = _data3 === null || _data3 === void 0 ? void 0 : _data3.toolName;

                    if (toolName) {
                      loadingText.value = translateWords[toolName] || toolName;
                    }
                  }
                });
                controller.enqueue(value);
                push();
              }).catch(function (error) {
                self.loading = false;
                console.error("Fetch error:", error);
                controller.error(error);
              });
            }

            push();
          }
        });
        return new Response(stream);
      });
    }

    function likeFeedback() {
      feedbackLoading.value = true;
      feedbackForm.value.type = "like";
      submitFeedback(assistantMessageId.value, feedbackForm.value.type, "").then(function () {
        ElMessage({
          message: "感谢您的反馈",
          type: "success"
        });
      }).catch(function () {
        ElMessage({
          message: "提交失败, 请稍后再试",
          type: "error"
        });
      }).finally(function () {
        feedbackLoading.value = false;
      });
    }

    function dislikeFeedback() {
      feedbackLoading.value = true;
      feedbackForm.value.type = "dislike";
      submitFeedback(assistantMessageId.value, feedbackForm.value.type, feedbackForm.value.comment).then(function () {
        feedbackDialogVisible.value = false;
        feedbackForm.value.comment = "";
        ElMessage({
          message: "感谢您的反馈",
          type: "success"
        });
      }).catch(function () {
        ElMessage({
          message: "提交失败, 请稍后再试",
          type: "error"
        });
      }).finally(function () {
        feedbackLoading.value = false;
      });
    }

    function copyAnswer() {
      navigator.clipboard.writeText(displayedAnswer.value);
      ElMessage({
        message: "已复制到剪贴板",
        type: "success"
      });
    }

    onMounted(function () {
      localKeyword.value = props.keyword;
      askTiDBAI();
    });
    return {
      localKeyword: localKeyword,
      displayedAnswer: displayedAnswer,
      loading: loading,
      loadingText: loadingText,
      assistantMessageId: assistantMessageId,
      feedbackDialogVisible: feedbackDialogVisible,
      feedbackForm: feedbackForm,
      feedbackLoading: feedbackLoading,
      likeFeedback: likeFeedback,
      dislikeFeedback: dislikeFeedback,
      copyAnswer: copyAnswer
    };
  }
};