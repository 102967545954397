import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from 'vue-router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import SearchIndex from './pages/SearchIndex'

const routes = [
    { path: '/', name: 'index', component: SearchIndex },
    { path: '/doc', name: 'docs-cn', component: SearchIndex },
    { path: '/asktug', name: 'asktug', component: SearchIndex },
    { path: '/blog', name: 'blog', component: SearchIndex },
    { path: '/community-blog', name: 'community-blog', component: SearchIndex },
    { path: '/pe', name: 'pe', component: SearchIndex },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
app.use(router)
app.use(Quasar, quasarUserOptions)
app.mount('#app')
