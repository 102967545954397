import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  id: "search-index-wrapper"
};
var _hoisted_2 = {
  class: "flex top"
};
var _hoisted_3 = {
  class: "input-containner"
};
var _hoisted_4 = {
  key: 0,
  class: "suggestions"
};
var _hoisted_5 = {
  class: "search-types-wrapper"
};
var _hoisted_6 = {
  class: "search-types-container"
};
var _hoisted_7 = {
  class: "flex"
};
var _hoisted_8 = {
  class: "result-container"
};
var _hoisted_9 = {
  key: 3,
  class: "q-pa-lg flex flex-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_q_icon = _resolveComponent("q-icon");

  var _component_router_link = _resolveComponent("router-link");

  var _component_EmptyKeywords = _resolveComponent("EmptyKeywords");

  var _component_NoResult = _resolveComponent("NoResult");

  var _component_TiDBAI = _resolveComponent("TiDBAI");

  var _component_Results = _resolveComponent("Results");

  var _component_q_pagination = _resolveComponent("q-pagination");

  var _component_ResultRightSide = _resolveComponent("ResultRightSide");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "search-container",
    onFocusCapture: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.foucsInputFlag = true;
    }),
    onBlurCapture: _cache[3] || (_cache[3] = function () {
      return _ctx.searchLostFoucs && _ctx.searchLostFoucs.apply(_ctx, arguments);
    })
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_q_icon, {
    name: "search"
  }), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("input", {
    type: "text",
    name: "q",
    ref: "search",
    maxlength: "1024",
    autocomplete: "off",
    autocorrect: "off",
    spellcheck: "false",
    autocapitalize: "off",
    title: "AskTUG 搜索",
    placeholder: "输入关键词, 按回车搜索",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.keyword = $event;
    }),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(function () {
      return _ctx.refreshAndSearch && _ctx.refreshAndSearch.apply(_ctx, arguments);
    }, ["enter"]))
  }, null, 544), [[_vModelText, _ctx.keyword]])])]), _ctx.foucsInputFlag && _ctx.keywordSuggestions !== undefined && _ctx.keywordSuggestions.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[5] || (_cache[5] = _createElementVNode("div", {
    class: "divider"
  }, null, -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywordSuggestions, function (s) {
    return _openBlock(), _createBlock(_component_router_link, {
      key: s,
      to: "/?q=".concat(s)
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("li", null, _toDisplayString(s), 1)];
      }),
      _: 2
    }, 1032, ["to"]);
  }), 128))])])) : _createCommentVNode("", true)], 32), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", _hoisted_6, [_createVNode(_component_router_link, {
    to: "/?q=".concat(_ctx.keyword || ''),
    tag: "li",
    class: "item"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "zoom_in"
      }), _cache[6] || (_cache[6] = _createTextVNode("全部 "))];
    }),
    _: 1
  }, 8, ["to"]), _createVNode(_component_router_link, {
    to: "/asktug?q=".concat(_ctx.keyword || ''),
    tag: "li",
    class: "item"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "forum"
      }), _cache[7] || (_cache[7] = _createTextVNode("问答 "))];
    }),
    _: 1
  }, 8, ["to"]), _createVNode(_component_router_link, {
    to: "/community-blog?q=".concat(_ctx.keyword || ''),
    tag: "li",
    class: "item"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "newspaper"
      }), _cache[8] || (_cache[8] = _createTextVNode("专栏 "))];
    }),
    _: 1
  }, 8, ["to"]), _createVNode(_component_router_link, {
    to: "/doc?q=".concat(_ctx.keyword || ''),
    tag: "li",
    class: "item"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "description"
      }), _cache[9] || (_cache[9] = _createTextVNode("官方文档 "))];
    }),
    _: 1
  }, 8, ["to"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [!_ctx.keyword && _ctx.hits.length === 0 ? (_openBlock(), _createBlock(_component_EmptyKeywords, {
    key: 0
  })) : _createCommentVNode("", true), _ctx.keyword && _ctx.resultsMeta.total === 0 ? (_openBlock(), _createBlock(_component_NoResult, {
    key: 1,
    keyword: _ctx.keyword
  }, null, 8, ["keyword"])) : _createCommentVNode("", true), _ctx.hasResults && _ctx.resultsMeta.total ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [_ctx.canUseTiDBAI && _ctx.searchSource === '' ? (_openBlock(), _createBlock(_component_TiDBAI, {
    key: 0,
    keyword: _ctx.keyword,
    class: "tidb-ai-card"
  }, null, 8, ["keyword"])) : _createCommentVNode("", true), _ctx.hasResults && _ctx.resultsMeta.total ? (_openBlock(), _createBlock(_component_Results, {
    key: 1,
    hits: _ctx.hits,
    resultsMeta: _ctx.resultsMeta,
    user: _ctx.user,
    searchTrackId: _ctx.searchTrackId,
    searchSource: _ctx.searchSource
  }, null, 8, ["hits", "resultsMeta", "user", "searchTrackId", "searchSource"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), _ctx.maxPage !== 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_q_pagination, {
    color: "red-10",
    modelValue: _ctx.page,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.page = $event;
    }),
    max: _ctx.maxPage,
    "max-pages": 6,
    "boundary-numbers": "",
    "direction-links-": ""
  }, null, 8, ["modelValue", "max"])])) : _createCommentVNode("", true)]), _createVNode(_component_ResultRightSide, {
    class: "result-right-side"
  })])]);
}