import { post, get } from './http'

export const searchKeyword = (keyword, page, perPage, source, track_id) => {
    let body = {
        'q': keyword,
        'per_page': perPage,
        'page_num': page,
        'source': source,
        'enable_track': true,
        'track_id': track_id
    }
    return post(`/api/search/v2`, body)
}


export const reportClick = (url, searchTrackId) => post(`/api/track/${searchTrackId}`, { url })


export const getKeywordSuggestions = (keyword) => {
    return get(`/api/search/keyword-suggestions`, {
        params: { 'q': keyword }
    })
}
