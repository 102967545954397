import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = ["href"];
var _hoisted_2 = {
  class: "item-url"
};
var _hoisted_3 = {
  key: 0,
  class: "solved"
};
var _hoisted_4 = {
  class: "item-bottom"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "badges-row-container"
};
var _hoisted_8 = {
  key: 0,
  class: "from"
};
var _hoisted_9 = {
  key: 1,
  class: "score"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_q_badge = _resolveComponent("q-badge");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("a", {
    href: $props.data.url,
    class: "item-top",
    target: "_blank",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    }),
    onContextmenu: _cache[1] || (_cache[1] = function () {
      return $setup.reportClickResult && $setup.reportClickResult.apply($setup, arguments);
    })
  }, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.data.url), 1), _createElementVNode("h3", null, [$props.data.data.topic_is_solved ? (_openBlock(), _createElementBlock("span", _hoisted_3, "[已解决]")) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.data.data.title), 1)])], 40, _hoisted_1), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", {
    innerHTML: $props.data.data.content
  }, null, 8, _hoisted_5), !$props.data.data.content ? (_openBlock(), _createElementBlock("p", _hoisted_6, "......(点击链接 ↑ 查看详情)")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "badge-category-bg",
    style: {
      "background-color": "#3ab54a"
    }
  }, null, -1)), _cache[3] || (_cache[3] = _createElementVNode("span", {
    class: "from"
  }, "AskTUG", -1)), _cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "badge-category-bg",
    style: {
      "background-color": "grey",
      "margin-left": "15px"
    }
  }, null, -1)), $props.data.data.category_name ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($props.data.data.category_name), 1)) : _createCommentVNode("", true), $props.user && $props.user.isStaff ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_q_badge, {
    color: "blue"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode("Score: " + _toDisplayString($props.data.score), 1)];
    }),
    _: 1
  })])) : _createCommentVNode("", true)])])], 64);
}