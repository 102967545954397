import { ref, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "LoadingText",
  props: ["text"],
  setup: function setup() {
    var dots = ref("");
    var intervalId;
    onMounted(function () {
      intervalId = setInterval(function () {
        dots.value = dots.value.length < 5 ? dots.value + "." : "";
      }, 600);
    });
    onBeforeUnmount(function () {
      clearInterval(intervalId);
    });
    return {
      dots: dots
    };
  }
};