import axios from 'axios'
import Cookies from 'js-cookie'

let baseUrl = window.location.origin
axios.defaults.baseURL = baseUrl
axios.defaults.timeout = 50000
axios.defaults.withCredentials = true

axios.defaults.headers.post['X-CSRFToken'] = Cookies.get('csrftoken')
axios.defaults.headers.put['X-CSRFToken'] = Cookies.get('csrftoken')
axios.defaults.headers.delete['X-CSRFToken'] = Cookies.get('csrftoken')

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.error(error)
  }
)

export function get (url, data) {
  return axios.get(url, data, {
    withCredentials: true
  })
}

export function post (url, data) {
  return axios.post(url, data)
}

export function put (url, data) {
  return axios.put(url, data)
}

export function del (url, data) {
  return axios.delete(url, data)
}

export function uploader (url, file) {
  let params = new FormData()
  params.append('file', file)
  return axios.post(url, params)
}