
import './styles/quasar.sass'
import lang from 'quasar/lang/zh-CN.js'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading
  },
  lang: lang
}