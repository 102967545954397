import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '@/assets/ti.png';
var _hoisted_1 = {
  class: "ai-answer-card"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "answer"
};
var _hoisted_4 = {
  key: 0,
  class: "actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_LoadingText = _resolveComponent("LoadingText");

  var _component_VMarkdownView = _resolveComponent("VMarkdownView");

  var _component_q_icon = _resolveComponent("q-icon");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  var _component_el_dialog = _resolveComponent("el-dialog");

  var _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[5] || (_cache[5] = _createStaticVNode("<div class=\"header\" data-v-fe19940a><div class=\"ai-icon-title\" data-v-fe19940a><img src=\"" + _imports_0 + "\" class=\"ai-icon\" alt=\"TiDB AI\" data-v-fe19940a><span class=\"title\" data-v-fe19940a>TiDB AI 小助手</span></div><div class=\"beta-warning\" data-v-fe19940a> (该机器人仍在测试阶段，生成的信息可能不准确。) </div></div>", 1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.loading ? (_openBlock(), _createBlock(_component_LoadingText, {
    key: 0,
    text: $setup.loadingText
  }, null, 8, ["text"])) : _createCommentVNode("", true), _createVNode(_component_VMarkdownView, {
    content: $setup.displayedAnswer,
    mode: "light"
  }, null, 8, ["content"])])]), $setup.assistantMessageId && !$setup.loading ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: $setup.likeFeedback
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: $setup.feedbackForm.type === 'like' ? 'thumb_up_alt' : 'thumb_up_off_alt'
      }, null, 8, ["name"])];
    }),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      $setup.feedbackDialogVisible = true;
      $setup.feedbackForm.type = 'dislike';
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: $setup.feedbackForm.type === 'dislike' ? 'thumb_down_alt' : 'thumb_down_off_alt'
      }, null, 8, ["name"])];
    }),
    _: 1
  }), _createVNode(_component_el_button, {
    type: "",
    link: "",
    onClick: $setup.copyAnswer
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_q_icon, {
        name: "content_copy"
      })];
    }),
    _: 1
  }, 8, ["onClick"])])), [[_directive_loading, $setup.feedbackLoading]]) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
    modelValue: $setup.feedbackDialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $setup.feedbackDialogVisible = $event;
    }),
    title: "提交反馈",
    width: "500"
  }, {
    default: _withCtx(function () {
      return [_withDirectives((_openBlock(), _createBlock(_component_el_form, {
        model: $setup.feedbackForm
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: "优化建议",
            "label-position": "top"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_input, {
                modelValue: $setup.feedbackForm.comment,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                  return $setup.feedbackForm.comment = $event;
                }),
                autosize: {
                  minRows: 2
                },
                type: "textarea",
                placeholder: "",
                maxlength: "200"
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return $setup.dislikeFeedback();
            })
          }, {
            default: _withCtx(function () {
              return _cache[4] || (_cache[4] = [_createTextVNode("提交反馈")]);
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["model"])), [[_directive_loading, $setup.feedbackLoading]])];
    }),
    _: 1
  }, 8, ["modelValue"])]);
}